import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Layout from '../components/layout';

let SignIn = React.lazy( () => import( '../components/pages/others/sign-in' ) );

let Dashboard = React.lazy( () => import( '../components/pages/dashboard' ) );
let MediaPages = React.lazy( () => import( './media' ) );
let TagsEdit = React.lazy( () => import( '../components/pages/tags/tags-edit' ) );
let CategoriesEdit = React.lazy( () => import( '../components/pages/categories/categories-edit' ) );
let ProductsPages = React.lazy( () => import( './products' ) );
let PostsPages = React.lazy( () => import( './posts' ) );
let EcommercePages = React.lazy( () => import( './ecommerce' ) );
let UsersPages = React.lazy( () => import( './users' ) );
let MultivendorPages = React.lazy( () => import( './multivendor' ) );
let ElementsPages = React.lazy( () => import( './elements' ) );
let FormsPages = React.lazy( () => import( './forms' ) );
let TablesPages = React.lazy( () => import( './tables' ) );
let OtherPages = React.lazy( () => import( './others' ) );

let TaxList = React.lazy( () => import( '../components/pages/tax/tax-list' ) );
let TaxEdit = React.lazy( () => import( '../components/pages/tax/tax-edit' ) );

let TaxStatusList = React.lazy( () => import( '../components/pages/tax/tax-status-list' ) );
let TaxStatusEdit = React.lazy( () => import( '../components/pages/tax/tax-status-edit' ) );

let TaxClassList = React.lazy( () => import( '../components/pages/tax/tax-class-list' ) );
let TaxClassEdit = React.lazy( () => import( '../components/pages/tax/tax-class-edit' ) );

let StockStatus = React.lazy( () => import( '../components/pages/stock-status/stock-status-list' ) );
let StockStatusEdit = React.lazy( () => import( '../components/pages/stock-status/stock-status-edit' ) );

let BrandList = React.lazy( () => import( '../components/pages/brand/brand-list' ) );
let BrandEdit = React.lazy( () => import( '../components/pages/brand/brand-edit' ) );

let LevelEdit= React.lazy( () => import( '../components/pages/level/level-edit' ) );
let LevelList = React.lazy( () => import( '../components/pages/level/level-list' ) );

let CountryList = React.lazy( () => import( '../components/pages/country/country-list' ) );
let CountryEdit= React.lazy( () => import( '../components/pages/country/country-edit' ) );
let UserProfile= React.lazy( () => import( '../components/pages/others/user-profile' ) );

let ThuiskopieList = React.lazy( () => import( '../components/pages/thuiskopie/thuiskopie-list' ) );
let ThuiskopieEdit = React.lazy( () => import( '../components/pages/thuiskopie/thuiskopie-edit' ) );


export default function Routes () {

    
    return (
        <React.Suspense fallback={
            <div className="loading-overlay">
                <div className="bounce-loader">
                    <div className="bounce1"></div>
                    <div className="bounce2"></div>
                    <div className="bounce3"></div>
                </div>
            </div> }>
            <Switch>
                {/* <Route path={ `${ process.env.PUBLIC_URL }/pages` } component={ OtherPages } /> */}
                <Route exact path={ `${ process.env.PUBLIC_URL }/` } component={ SignIn } />

                <Layout>

                    <Route exact path={ `${ process.env.PUBLIC_URL }/profile/:id` } component={ UserProfile } />
                    <Route exact path={ `${ process.env.PUBLIC_URL }/dashboard` } component={ Dashboard } />
                    <Route path={ `${ process.env.PUBLIC_URL }/media` } component={ MediaPages } />
                    <Route path={ `${ process.env.PUBLIC_URL }/products` } component={ ProductsPages } />
                    <Route path={ `${ process.env.PUBLIC_URL }/posts` } component={ PostsPages } />
                    <Route exact path={ `${ process.env.PUBLIC_URL }/tags/:id` } component={ TagsEdit } />
                    <Route exact path={ `${ process.env.PUBLIC_URL }/categories/:id` } component={ CategoriesEdit } />
                    <Route path={ `${ process.env.PUBLIC_URL }/ecommerce` } component={ EcommercePages } />
                    <Route path={ `${ process.env.PUBLIC_URL }/multivendor` } component={ MultivendorPages } />
                    <Route path={ `${ process.env.PUBLIC_URL }/users` } component={ UsersPages } />
                    <Route path={ `${ process.env.PUBLIC_URL }/elements` } component={ ElementsPages } />
                    <Route path={ `${ process.env.PUBLIC_URL }/forms` } component={ FormsPages } />
                    <Route path={ `${ process.env.PUBLIC_URL }/tables` } component={ TablesPages } />

                    <Route exact path={ `${ process.env.PUBLIC_URL }/tax` } component={ TaxList }  />
                    <Route exact path={ `${ process.env.PUBLIC_URL }/tax/:id` } component={ TaxEdit } />

                    <Route exact path={ `${ process.env.PUBLIC_URL }/tax-status` } component={ TaxStatusList }  />
                    <Route exact path={ `${ process.env.PUBLIC_URL }/tax-status/:id` } component={ TaxStatusEdit } />

                    <Route exact path={ `${ process.env.PUBLIC_URL }/tax-class` } component={ TaxClassList }  />
                    <Route exact path={ `${ process.env.PUBLIC_URL }/tax-class/:id` } component={ TaxClassEdit } />

                    <Route exact path={ `${ process.env.PUBLIC_URL }/stock-status` } component={ StockStatus }  />
                    <Route exact path={ `${ process.env.PUBLIC_URL }/stock-status/:id` } component={ StockStatusEdit } />

                    <Route exact path={ `${ process.env.PUBLIC_URL }/brands` } component={ BrandList }  />
                    <Route exact path={ `${ process.env.PUBLIC_URL }/brand/:id` } component={ BrandEdit } />

                    <Route exact path={ `${ process.env.PUBLIC_URL }/levels` } component={ LevelList } />
                    <Route exact path={ `${ process.env.PUBLIC_URL }/level/:id` } component={ LevelEdit } />

                    <Route exact path={ `${ process.env.PUBLIC_URL }/location/countries` } component={ CountryList } />
                    <Route exact path={ `${ process.env.PUBLIC_URL }/location/country/:id` } component={ CountryEdit } />

                    <Route exact path={ `${ process.env.PUBLIC_URL }/thuiskopie` } component={ ThuiskopieList }  />
                    <Route exact path={ `${ process.env.PUBLIC_URL }/thuiskopie/:id` } component={ ThuiskopieEdit } />


                </Layout>
            </Switch>
        </React.Suspense>
    )
}