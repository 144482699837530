import axios from 'axios'; 

const authToken = window.localStorage.getItem('authToken');

const axiosIntance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
        'Authorization': authToken,
        'Accept' : 'application/json',
        'Content-Type': 'application/json'
    }
});


axiosIntance.interceptors.response.use((response) => {
    if(response.data.body.session== -101){
        localStorage.clear();
        window.location.href = '/'
    }else{
        return response;
    }
}, (error) => {
    const status = error.response ? error.response.status : 500;
    if(status && status ===400 || status ===500){ 
        localStorage.clear();
        window.location.href = '/';
    }
    return Promise.reject(error);
})

export default axiosIntance;