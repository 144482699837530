
import { configureStore } from "@reduxjs/toolkit";
import authReducer from './slices/loginSlice';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';

const persistConfig = {
  key: 'root',
  storage,
}

const persistedUser = persistReducer(persistConfig, authReducer)

export const store = configureStore({
  reducer: {
    user:persistedUser,
},
  devTools: true,
  middleware: [thunk]
})

export const persistor = persistStore(store)