import React, { useState, useEffect } from 'react';
import Link from 'react-router-dom/Link';
import { Button, Collapse, Dropdown, ProgressBar, InputGroup, Form } from 'react-bootstrap';
import { SlideToggle } from 'react-slide-toggle';
import { useSelector,useDispatch } from "react-redux";

import menuData from '../../utils/data/menu.json';
import Config from '../../config.json';
import { adminLogout } from "../../redux/slices/loginSlice";
import { toast } from 'react-toastify';
import PNotify from '../features/elements/p-notify';
// import { checkSession } from '../../api';

export default function Header () {

    const dispatch = useDispatch();

    const [ showUserBox, toggleUserBox ] = useState( false );
    const [ menu, toggleMenu ] = useState( false );
    const [ search, toggleSearch ] = useState( false );
    const [ location, setLocation ] = useState( false );

    const {userInfo,isLoggedIn} = useSelector(state => state.user);

    const userID = userInfo.body ? userInfo.body.user._id :'';
  
    useEffect( () => {
        let authToken = localStorage.getItem('authToken');
        if(userID==null || userID==''){
            window.location.href= process.env.PUBLIC_URL + '/'
        }

        // checkSession().then(response =>{
        //     if(response.data.success == 0){
        //         dispatch(adminLogout(authToken))
        //         localStorage.removeItem('authToken');
        //         window.location.href = process.env.PUBLIC_URL + '/'
        //     }
        // }).catch(err=>{
        //     console.log('check session err= ',err)
        // })
        document.querySelector( 'body' ).addEventListener( 'click', onBodyClick );
    }, [] )

    function onToggleUserBox ( e ) {
        e.preventDefault();
        toggleUserBox( !showUserBox );
    }

    function onBodyClick ( e ) {
        if ( !e.target.closest( '.userbox' ) ) {
            toggleUserBox( false );
        }
    }

    function prevent ( e ) {
        e.preventDefault();
    }

    function logout(e){
        e.preventDefault();
        let authToken = {
            token:userInfo.body.user.token
        }

        dispatch(adminLogout(authToken))
        .unwrap()
        .then(result=>{
            if(result.success==1){
                localStorage.removeItem('authToken');
                toast(
                    <PNotify title="Success" icon="fas fa-check" text={result.message} />,
                    {
                        containerId: "default",
                        className: "notification-success"
                    }
                );
                setTimeout(()=>{ setLocation(true)},1000)
            }
        }).catch(error=>{

        })
    }

    if(location){
        window.location.href='/'
    }
    if(!isLoggedIn){
        setTimeout(()=> window.location.href='/' ,1000)
    }
    return (
        <header className="header header-nav-menu header-nav-links">
            <div className="logo-container">
                <Link to={ `${ process.env.PUBLIC_URL }` } className="logo">
                    <img src={ `${ Config.ICON_URL }/tboriginal.png` } className="logo-image" width="90" height="24" alt="Techbyte Admin" />
                    <img src={ `${ Config.ICON_URL }/tboriginal.png` } className="logo-image-mobile" width="90" height="41" alt="Techbyte Admin" />
                </Link>
                <Button
                    variant=""
                    className="header-btn-collapse-nav d-md-none"
                    onClick={ () => toggleMenu( !menu ) }
                >
                    <i className="fas fa-bars"></i>
                </Button>

                <Collapse in={ menu }>
                    <div className="header-nav d-md-none">
                        <div className="header-nav-main header-nav-main-effect-1 header-nab-main-sub-effect-1 header-nav-main-square">
                            <nav>
                                <ul className="nav nav-pills">
                                    {
                                        menuData.map( ( item, index ) => (
                                            <li className={ item.children ? 'dropdown' : '' } key={ `dropdown-${ index }` }>
                                                {
                                                    item.children ?
                                                        <>
                                                            <SlideToggle collapsed={ true }>
                                                                { ( { onToggle, setCollapsibleElement } ) => (
                                                                    <>
                                                                        <a href="#no" className="nav-link dropdown-toggle" onClick={ ( e ) => { e.preventDefault(); onToggle() } }>
                                                                            { item.name }
                                                                            <i className="fas fa-caret-down"></i>
                                                                        </a>

                                                                        <ul className="dropdown-menu" ref={ setCollapsibleElement }>
                                                                            {
                                                                                item.children.map( ( subMenu, subId ) => (
                                                                                    <li className={ subMenu.children ? 'dropdown-submenu' : '' } key={ `dropdown-${ index }-${ subId }` }>
                                                                                        {
                                                                                            subMenu.children ?
                                                                                                <SlideToggle collapsed={ true }>
                                                                                                    { ( { onToggle, setCollapsibleElement } ) => (
                                                                                                        <>
                                                                                                            <a href="#no" className="nav-link dropdown-toggle" onClick={ ( e ) => { e.preventDefault(); onToggle() } }>
                                                                                                                { subMenu.name }
                                                                                                                <i className="fas fa-caret-down"></i>
                                                                                                            </a>
                                                                                                            <ul className="dropdown-menu" ref={ setCollapsibleElement }>
                                                                                                                {
                                                                                                                    subMenu.children.map( ( ssubMenu, ssubId ) => (
                                                                                                                        <li key={ `dropdown-${ index }-${ subId }-${ ssubId }` }>
                                                                                                                            <Link to={ process.env.PUBLIC_URL + ssubMenu.url } className="nav-link">{ ssubMenu.name }</Link>
                                                                                                                        </li>
                                                                                                                    ) )
                                                                                                                }
                                                                                                            </ul>
                                                                                                        </>

                                                                                                    ) }
                                                                                                </SlideToggle>
                                                                                                : <Link to={ process.env.PUBLIC_URL + subMenu.url } className="nav-link">{ subMenu.name }</Link>
                                                                                        }
                                                                                    </li>
                                                                                ) )
                                                                            }
                                                                        </ul>
                                                                    </>
                                                                ) }
                                                            </SlideToggle>
                                                        </>
                                                        : <Link to={ process.env.PUBLIC_URL + item.url } className="nav-link">{ item.name }</Link>
                                                }
                                            </li>
                                        ) )
                                    }
                                </ul>
                            </nav>
                        </div>
                    </div>
                </Collapse>

                <div className="header-nav show d-none d-md-block">
                    <div className="header-nav-main">
                    </div>
                </div>
            </div>

            <div className="header-right">
                {/* <Button variant="" className="search-toggle d-none d-md-inline-block d-xl-none" onClick={ () => toggleSearch( !search ) }><i className="bx bx-search"></i></Button>
                <Form action="#" className={ `search search-style-1 nav-form d-none d-xl-inline-block ${ search ? "active" : "" }` } onSubmit={ prevent }>
                    <InputGroup>
                        <Form.Control type="text" className="form-control" placeholder="Search..." />
                        <InputGroup.Append>
                            <Button variant="default" type="submit"><i className="bx bx-search"></i></Button>
                        </InputGroup.Append>
                    </InputGroup>
                </Form> */}

                <span className="separator"></span>

                {/* <Dropdown className="d-none d-sm-block">
                    <Dropdown.Toggle as="a" className="dropdown-language nav-link">
                        <i className="flag flag-us"></i> EN
						<i className="fas fa-chevron-down"></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu alignRight={ true }>
                        <Dropdown.Item><i className="flag flag-us"></i> English</Dropdown.Item>
                        <Dropdown.Item><i className="flag flag-es"></i> Español</Dropdown.Item>
                        <Dropdown.Item><i className="flag flag-fr"></i> Française</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown> */}

                <ul className="notifications">
                
                    <li>
                        <Dropdown>
                            <Dropdown.Toggle as="a" className="notification-icon">
                                <i className="bx bx-bell"></i>
                                <span className="badge">0</span>
                            </Dropdown.Toggle>

                            <Dropdown.Menu className="notification-menu">
                                <div className="notification-title">
                                    <span className="float-right badge badge-default"></span> Alerts
                                </div>
                            </Dropdown.Menu>
                        </Dropdown>
                    </li>
                </ul>

                <span className="separator"></span>

                <div id="userbox" className={ `userbox ${ showUserBox ? 'show' : '' }` }>
                    <a href="#no" onClick={ onToggleUserBox }>
                        <span className="profile-picture profile-picture-as-text">Me</span>
                        <div className="profile-info profile-info-no-role">
                            <span className="name">Hi, <strong className="font-weight-semibold">
                                {
                                    userInfo ? userInfo.body.user.personName : ''
                                }
                            </strong></span>
                        </div>
                        <i className="fas fa-chevron-down text-color-dark"></i>
                    </a>

                    <div className={ `dropdown-menu ${ showUserBox ? 'show' : '' }` }>
                        <ul className="list-unstyled">
                            <li>
                                <Link to={ `${ process.env.PUBLIC_URL }/profile/${userID}` }><i className="bx bx-user"></i> My Profile</Link>
                            </li>
                            <li>
                                <Link to='' onClick={logout}><i className="bx bx-log-out"></i> Logout</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </header>
    )
}