import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import{ login,logout,updateAdminProfile } from "../../api";

const initialState = {
  loading: false,
  userInfo: null,
  error: null,
  success: false,
  isLoggedIn:false

};

export const adminLogin = createAsyncThunk(
    "admin/login",
    async (formData) => {
      const res = await login(formData);
      return res.data;
    }
  );

  export const adminLogout = createAsyncThunk(
    "admin/logout",
    async (formData) => {
      const res = await logout(formData);
      return res.data;
    }
  );

  export const adminProfileUpdate = createAsyncThunk(
    "admin/update",
    async (formData) => {
      const res = await updateAdminProfile(formData);
      return res.data;
    }
  );

  const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers:{},
    extraReducers: {

      [adminLogin.pending]: (state) => {
        state.loading = true
        state.error = null
      },
      [adminLogin.fulfilled]: (state, action) => {
        state.userInfo = action.payload
        state.isLoggedIn=true
      },
      [adminLogin.rejected]: (state, { payload }) => {
        state.loading = false
        state.error = payload
        state.isLoggedIn=false
      },

      [adminLogout.pending]: (state) => {
        state.loading = true
        state.error = null
      },
      [adminLogout.fulfilled]: (state, action) => {
        state.userInfo = ''
        state.isLoggedIn=false
      },
      [adminLogout.rejected]: (state, { payload }) => {
        state.loading = false
        state.error = payload
        state.isLoggedIn=false
      },

      [adminProfileUpdate.pending]: (state) => {
        state.loading = true
        state.error = null
      },
      [adminProfileUpdate.fulfilled]: (state, action) => {
        state.userInfo = action.payload
        state.isLoggedIn=true
      },
      [adminProfileUpdate.rejected]: (state, { payload }) => {
        state.loading = false
        state.error = payload
        state.isLoggedIn=false
      },

    },
  });
  
  const { reducer } = authSlice;
  export default reducer;