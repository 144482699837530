import axios from 'axios';
import Config from '../config.json';
import { getCategoryTree } from '../utils';
import axiosIntance from './axios';

const authToken = localStorage.getItem('authToken');
const API_URL = process.env.PUBLIC_URL;
const API_URL1 = Config.API_URL+'/admin';

const headers = {
    headers: {
        'Authorization': authToken,
        'Accept' : 'application/json',
        'Content-Type': 'application/json'
    }
}
// APIs to get media from mock server
export const getMedia = function ( from = 0, to, filters, sortBy ) {
    return axios.get( API_URL + '/mock-server/media.json' )
        .then( response => {
            let results = response.data;

            filters && filters.forEach( filter => {
                results = results.filter( media => media[ filter.id ].search( new RegExp( filter.value, 'i' ) ) >= 0 );
            } );

            sortBy && sortBy.forEach( sort => {
                let index = sort.desc ? -1 : 1;
                switch ( sort.id ) {
                    case 'name':
                        results = results.sort( ( a, b ) => a.name < b.name ? -index : index );
                        break;
                    case 'uploaded_by':
                        results = results.sort( ( a, b ) => a.uploaded_by < b.uploaded_by ? -index : index );
                        break;
                    case 'size':
                        results = results.sort( ( a, b ) => ( a.size - b.size ) * index );
                        break;
                    case 'created_at':
                        results = results.sort( ( a, b ) => ( new Date( a.created_at ) - new Date( b.created_at ) ) * index );
                        break;
                    default:
                        break;
                }
            } );

            return {
                data: results.slice( from, to ),
                total: results.length
            }
        } )
        .catch( error => console.log( error ) );
}

export const getSingleMedia = function ( id ) {
    return axios.get( API_URL + '/mock-server/media.json' )
        .then( response => {
            return response.data.find( media => media.id === id );
        } )
        .catch( error => console.log( error ) );
}

// APIs to get tags from mock-server
export const getTags = function ( type, from = 0, to, filters, sortBy ) {
    return axios.get( API_URL + '/mock-server/tags.json' )
        .then( response => {
            let results = response.data.filter( tag => tag.type + 's' === type );

            filters && filters.forEach( filter => {
                results = results.filter( tag => tag[ filter.id ].search( new RegExp( filter.value, 'i' ) ) >= 0 );
            } );

            sortBy && sortBy.forEach( sort => {
                let index = sort.desc ? -1 : 1;
                switch ( sort.id ) {
                    case 'name':
                        results = results.sort( ( a, b ) => a.name < b.name ? -index : index );
                        break;
                    case 'slug':
                        results = results.sort( ( a, b ) => a.slug < b.slug ? -index : index );
                        break;
                    case 'description':
                        results = results.sort( ( a, b ) => a.description < b.description ? -index : index );
                        break;
                    case 'count':
                        results = results.sort( ( a, b ) => ( a.count - b.count ) * index );
                        break;
                    default:
                        break;
                }
            } );

            return {
                data: results.slice( from, to ),
                total: results.length
            }
        } )
        .catch( error => console.log( error ) );
}

export const getTag = function ( id ) {
    return axios.get( API_URL + '/mock-server/tags.json' )
        .then( response => {
            return response.data.find( tag => tag.id === id );
        } )
        .catch( error => console.log( error ) );
}

export const checkSession = function(){
    return axios.get(API_URL1+'/checksession',headers)
    .then(response=>{
        
        return response;
    }).catch(error =>{ console.log( error ) })
}
// APIs to get categories from server
export const getCategories = function ( type, from = 0, to, filters, sortBy ) {
    return axiosIntance.get( API_URL1 + '/get-categories')
        .then( response => {
          console.log(response)
            let results = response.data.body;
            // results = getCategoryTree( results );
            // let tree = results;

            filters && filters.forEach( filter => {
                results = results.filter( cat => cat[ filter.id ].search( new RegExp( filter.value, 'i' ) ) >= 0 );
            } );

            sortBy && sortBy.forEach( sort => {
                let index = sort.desc ? -1 : 1;
                switch ( sort.id ) {
                    case 'name':
                        results = results.sort( ( a, b ) => a.name < b.name ? -index : index );
                        break;
                    case 'slug':
                        results = results.sort( ( a, b ) => a.slug < b.slug ? -index : index );
                        break;
                    case 'description':
                        results = results.sort( ( a, b ) => a.description < b.description ? -index : index );
                        break;
                    case 'count':
                        results = results.sort( ( a, b ) => ( a.count - b.count ) * index );
                        break;
                    default:
                        break;
                }
            } );

            return {
                data: results.slice( from, to ),
                total: results.length,
                // tree
            }
        } )
        .catch( error => console.log( error ) );
}

export const getCategory = function ( id ) {
    return axiosIntance.post( API_URL1 + '/get-category',{catId:id} )
        .then( response => {
            let cat = response.data.body;
            
            return {
                data: cat,
            };
        } )
        .catch( error => console.log( error ) );
}

export const updateCategory = function ( formData ) {
    return axiosIntance.post( API_URL1 + '/category-update',formData )
        .then( response => {
            return response;
        } )
        .catch( error => console.log( error ) );
}

export const deleteCategory = function ( id ) {
    return axiosIntance.post( API_URL1 + '/category-delete',{catId:id} )
        .then( response => {
            return response;
        } )
        .catch( error => console.log( error ) );
}

export const getParentCategory = function () {
    return axiosIntance.get( API_URL1 + '/get-parent-category')
        .then( response => {
            let cat = response.data.body;
            return {
                data: cat,
            };
        } )
        .catch( error => console.log( error ) );
}

export const addCategory = function ( data) {
    return axiosIntance.post( API_URL1 + '/add-category',data )
        .then( response => {
           
            return response;
        } )
        .catch( error => console.log( error ) );
}

export const getCategoriesTree = function () {
    return axiosIntance.get( API_URL1 + '/get-categories' )
        .then( response => {
            let results = response.data.body;
            return getCategoryTree( results );
        } )
        .catch( error => console.log( error ) );
}

// APIs to get attributes from server
export const getAttributes = function ( from = 0, to ) {
    return axiosIntance.get( API_URL1 + '/get-attributes' )
        .then( response => {
          
            let results = response.data.body;

            return {
                data: results.slice( from, to ),
                total: results.length
            }
        } )
        .catch( error => console.log( error ) );
}

export const addAttribute = function (data) {
    return axiosIntance.post( API_URL1 + '/add-attribute',data)
        .then( response => {
            return response;
        } )
        .catch( error => console.log( error ) );
}

export const syncSpecification = function () {
    return axiosIntance.get( API_URL1 + '/sync-specification' )
        .then( response => {
            console.log(response)
            return response;
        } )
        .catch( error => console.log( error ) );
}

export const getAttribute = function ( id ) {
    return axiosIntance.post( API_URL1 + '/get-attribute',{attrId:id})
        .then( response => {
            return response.data.body;
        } )
        .catch( error => console.log( error ) );
}

export const updateAttribute = function ( data ) {
    return axiosIntance.post( API_URL1 + '/attribute-update',data )
        .then( response => {
            return response;
        } )
        .catch( error => console.log( error ) );
}

export const deleteAtrribute = function ( data ) {
    return axiosIntance.post( API_URL1 + '/attribute-delete',{aId:data} )
        .then( response => {
            return response;
        } )
        .catch( error => console.log( error ) );
}

export const getAttributeTerms = function ( attrId, from = 0, to, filters, sortBy ) {
    return axiosIntance.post( API_URL1 + '/get-attribute-terms', {attrId:attrId})
        .then( response => {
            let results = response.data.body;

            filters && filters.forEach( filter => {
                results = results.filter( term => term[ filter.id ].search( new RegExp( filter.value, 'i' ) ) >= 0 );
            } );

            sortBy && sortBy.forEach( sort => {
                let index = sort.desc ? -1 : 1;
                switch ( sort.id ) {
                    case 'name':
                        results = results.sort( ( a, b ) => a.name < b.name ? -index : index );
                        break;
                    case 'slug':
                        results = results.sort( ( a, b ) => a.slug < b.slug ? -index : index );
                        break;
                    case 'description':
                        results = results.sort( ( a, b ) => a.description < b.description ? -index : index );
                        break;
                    case 'count':
                        results = results.sort( ( a, b ) => a.count - b.count );
                        break;
                    default:
                        break;
                }
            } );

            return {
                data: results.slice( from, to ),
                total: results.length
            }
        } )
        .catch( error => console.log( error ) );
}

export const addAttributeTerm = function ( data ) {
    return axiosIntance.post( API_URL1 + '/add-attribute-term',data )
        .then( response => {
            return response;
        } )
        .catch( error => console.log( error ) );
}

export const getAttributeTerm = function ( id ) {
    return axiosIntance.post( API_URL1 + '/get-attribute-term',{termId:id} )
        .then( response => {
          
            return response
        } )
        .catch( error => console.log( error ) );
}

export const updateAttributeTerm = function ( data ) {
    return axiosIntance.post( API_URL1 + '/attribute-term-update',data )
        .then( response => {
            return response
        } )
        .catch( error => console.log( error ) );
}

export const deleteAtrributeTerm = function ( id ) {
    return axiosIntance.post( API_URL1 + '/attribute-term-delete',{attrId:id} )
        .then( response => {
            return response
        } )
        .catch( error => console.log( error ) );
}


// APIs to get Tax Types
export const getTaxTypes = function ( from = 0, to, filters, sortBy ) {
    return axios.get( )
        .then( response => {
            let results = response.data;

            filters && filters.forEach( filter => {
                let pattern = new RegExp( filter.value, 'i' );
                results = results.filter( tax => {
                    if ( filter.id === '*' )
                        return tax.name.search( pattern ) >= 0 || tax.description.search( pattern ) >= 0;
                    return tax[ filter.id ].search( pattern ) >= 0;
                } );
            } );

            sortBy && sortBy.forEach( sort => {
                let index = sort.desc ? -1 : 1;
                switch ( sort.id ) {
                    case 'name':
                        results = results.sort( ( a, b ) => a.name < b.name ? -index : index );
                        break;
                    case 'description':
                        results = results.sort( ( a, b ) => a.description < b.description ? -index : index );
                        break;
                    default:
                        break;
                }
            } );

            return {
                data: results.slice( from, to ),
                total: results.length
            }
        } )
        .catch( error => console.log( error ) );
}

export const getTaxType = function ( id ) {
    return axios.get(  )
        .then( response => {
            return response.data.find( tax => tax.id === id );
        } )
        .catch( error => console.log( error ) );
}

// APIs to get posts
export const getPosts = function ( from = 0, to, filters, sortBy ) {
    return axios.get( )
        .then( response => {
            let results = response.data;

            filters && filters.forEach( filter => {
                results = results.filter( product => {
                    if ( filter.id === 'categories' )
                        return product.categories && product.categories.find( cat => cat.slug === filter.value );
                    else if ( filter.id === 'period' )
                        return product[ filter.id ] === filter.value;
                    return product[ filter.id ].search( new RegExp( filter.value, 'i' ) ) >= 0;
                } )
            } );

            sortBy && sortBy.forEach( sort => {
                let index = sort.desc ? -1 : 1;
                switch ( sort.id ) {
                    case 'title':
                        results = results.sort( ( a, b ) => a.name < b.name ? -index : index );
                        break;
                    case 'comments_count':
                        results = results.sort( ( a, b ) => ( a.comments_count - b.comments_count ) * index );
                        break;
                    case 'created_at':
                        results = results.sort( ( a, b ) => ( new Date( a.created_at ) - new Date( b.created_at ) ) * index );
                        break;
                    default:
                        break;
                }
            } );

            return {
                data: results.slice( from, to ),
                total: results.length
            }
        } )
        .catch( error => console.log( error ) );
}

export const getPost = function ( id ) {
    return axios.get(  )
        .then( response => {
            return response.data.find( post => post.id === id );
        } )
        .catch( error => console.log( error ) );
}

export const getPostPeriods = function () {
    return axios.get( )
        .then( response => {
            return response.data.reduce( ( acc, cur ) => {
                if ( !acc.includes( cur.period ) ) {
                    acc.push( cur.period );
                }
                return acc;
            }, [] );
        } )
        .catch( error => console.log( error ) );
}

// APIs to get users
export const getUsers = function ( from = 0, to, filters, sortBy ) {
    return axiosIntance.post( API_URL1 + '/users-list', { searchData:filters!=''? filters[0].value : '' } )
        .then( response => {
                let results = response.data.body;
                sortBy && sortBy.forEach( sort => {
                    let index = sort.desc ? -1 : 1;
                    switch ( sort.id ) {
                        case 'name':
                            results = results.sort( ( a, b ) => a.first_name < b.first_name ? -index : index );
                            break;
                        case 'email':
                            results = results.sort( ( a, b ) => a.email < b.email ? -index : index );
                            break;
                        case 'posts_count':
                            results = results.sort( ( a, b ) => ( a.posts_count - b.posts_count ) * index );
                            break;
                        default:
                            break;
                    }
                } );
    
                return {
                    data: results.slice( from, to ),
                    total: results.length
                }
        } )
        .catch( error => console.log( error ) );
}

export const getUser = function ( id ) {
    return axiosIntance.post( API_URL1 + '/get-user',{userId:id} )
        .then( response => {
            return response.data.body;
        } );
}

export const addUser = function ( data ) {
    return axiosIntance.post( API_URL1 + '/add-user',data )
        .then( response => {
            return response;
        } );
}

export const updateUser = function ( data ) {
    return axiosIntance.post( API_URL1 + '/user-update',data)
        .then( response => {
                return response;
        } );
}

export const approveUser = function ( id ) {
    return axiosIntance.post( API_URL1 + '/user-approve',{userId:id} )
        .then( response => {
                return response;
        } )
        .catch( error => console.log( error ) );
}

export const disableUser = function ( id ) {
    return axiosIntance.post( API_URL1 + '/user-disable',{userId:id} )
        .then( response => {
            return response;
        } )
        .catch( error => console.log( error ) );
}

export const deleteUser = function ( id ) {
    return axios.post( API_URL1 + '/user-delete',{userId:id}, headers )
        .then( response => {
            return response;
        } )
        .catch( error => console.log( error ) );
}

export const updateSubscription = function ( data ) {
    return axiosIntance.post( API_URL1 + '/update-subscription',data)
        .then( response => {
                return response;
        } );
}

// APIs to get product reviews
export const getReviews = function ( from = 0, to, filters, sortBy ) {
    return axios.get( )
        .then( response => {
            let results = response.data;

            filters && filters.forEach( filter => {
                results = results.filter( review => {
                    if ( filter.id === 'author' )
                        return review[ 'author_name' ] === filter.value;
                    return review[ filter.id ].search( new RegExp( filter.value, 'i' ) ) >= 0;
                } )
            } );

            sortBy && sortBy.forEach( sort => {
                let index = sort.desc ? -1 : 1;
                switch ( sort.id ) {
                    case 'author':
                        results = results.sort( ( a, b ) => a.author_name < b.author_name ? -index : index );
                        break;
                    case 'product':
                        results = results.sort( ( a, b ) => a.product_name < b.product_name ? -index : index );
                        break;
                    case 'rating':
                        results = results.sort( ( a, b ) => ( a.rating - b.rating ) * index );
                        break;
                    case 'created_at':
                        results = results.sort( ( a, b ) => ( new Date( a.created_at ) - new Date( b.created_at ) ) * index );
                        break;
                    default:
                        break;
                }
            } );

            return {
                data: results.slice( from, to ),
                total: results.length
            }
        } )
        .catch( error => console.log( error ) );
}

export const getReview = function ( id ) {
    return axios.get(  )
        .then( response => {
            return response.data.find( review => review.id === id );
        } );
}

// APIs to get post replies
export const getReplies = function ( from = 0, to, filters, sortBy ) {
    return axios.get(  )
        .then( response => {
            let results = response.data;

            filters && filters.forEach( filter => {
                results = results.filter( review => {
                    return review[ filter.id ].search( new RegExp( filter.value, 'i' ) ) >= 0;
                } )
            } );

            sortBy && sortBy.forEach( sort => {
                let index = sort.desc ? -1 : 1;
                switch ( sort.id ) {
                    case 'author':
                        results = results.sort( ( a, b ) => a.author_name < b.author_name ? -index : index );
                        break;
                    case 'post':
                        results = results.sort( ( a, b ) => a.post.title < b.post.title ? -index : index );
                        break;
                    case 'created_at':
                        results = results.sort( ( a, b ) => ( new Date( a.created_at ) - new Date( b.created_at ) ) * index );
                        break;
                    default:
                        break;
                }
            } );

            return {
                data: results.slice( from, to ),
                total: results.length
            }
        } )
        .catch( error => console.log( error ) );
}

export const getReply = function ( id ) {
    return axios.get()
        .then( response => {
            return response.data.find( review => review.id === id );
        } );
}



// APIs to get zone
export const getZones = function ( from = 0, to, filters, sortBy ) {
    return axios.get()
        .then( response => {
            let results = response.data;

            filters && filters.forEach( filter => {
                results = results.filter( zone => {
                    let search = new RegExp( filter.value, 'i' );
                    if ( filter.id === '*' )
                        return zone.name.search( search ) >= 0 ||
                            zone.shipping_zone_methods.find( method => method.name.search( search ) >= 0 ) ||
                            zone.shipping_locations.find( location => location.name.search( search ) >= 0 );
                    else if ( filter.id === 'methods' )
                        return zone.shipping_zone_methods.find( method => method.name.search( search ) >= 0 );
                    else if ( filter.id === 'regions' )
                        return zone.shipping_locations.find( location => location.name.search( search ) >= 0 );
                    return zone[ filter.id ].search( search ) >= 0;
                } )
            } );

            sortBy && sortBy.forEach( sort => {
                let index = sort.desc ? -1 : 1;
                switch ( sort.id ) {
                    case 'name':
                        results = results.sort( ( a, b ) => ( a.name < b.name ? -index : index ) );
                        break;
                    default:
                        break;
                }
            } );

            return {
                data: results.slice( from, to ),
                total: results.length
            }
        } )
        .catch( error => console.log( error ) );
}

export const getZone = function ( id ) {
    return axios.get()
        .then( response => {
            return response.data.find( zone => zone.id === id );
        } );
}

// APIs to get customers
export const getCustomers = ( from = 0, to, filters, sortBy ) => {
    return axiosIntance.get( API_URL1 + '/get-customers' )
        .then( response => {
                let results = response.data.body;

                filters && filters.forEach( filter => {
                    results = results.filter( customer => customer[ filter.id ].search( new RegExp( filter.value, 'i' ) ) >= 0 );
                } );

                sortBy && sortBy.forEach( sort => {
                    let index = sort.desc ? -1 : 1;
                    switch ( sort.id ) {
                        case 'name':
                            results = results.sort( ( a, b ) => ( a.name < b.name ? -index : index ) );
                            break;
                        case 'lastActive':
                            results = results.sort( ( a, b ) => ( new Date( a.last_active ) - new Date( b.last_active ) ) * index );
                            break;
                        case 'orders':
                            results = results.sort( ( a, b ) => ( a.orders - b.orders ) * index );
                            break;
                        case 'totalSpend':
                            results = results.sort( ( a, b ) => ( a.total_spend - b.total_spend ) * index );
                            break;
                        default:
                            break;
                    }
                } );

                return {
                    data: results.slice( from, to ),
                    total: results.length
                }
        }).catch( error => console.log( error ) );
}

// APIs to get orders
export const getOrders = ( from = 0, to, filters, sortBy ) => {
    return axiosIntance.get( API_URL1 + '/get-orders' )
        .then( response => {
            let results = response.data.body;
            let periods = results.map( order => order.period );

            filters && filters.forEach( filter => {
                results = results.filter( order => {
                    if ( filter.id !== 'id' ) {
                        return order[ filter.id ] === filter.value;
                    }
                    return ( order.id.toString() ).search( new RegExp( filter.value, 'i' ) ) >= 0;
                } );
            } )

            sortBy && sortBy.forEach( sort => {
                let index = sort.desc ? -1 : 1;
                switch ( sort.id ) {
                    case 'id':
                        results = results.sort( ( a, b ) => ( a.id - b.id ) * index );
                        break;
                    case 'created_at':
                        results = results.sort( ( a, b ) => ( new Date( a.created_at ) - new Date( b.created_at ) ) * index );
                        break;
                    case 'total':
                        results = results.sort( ( a, b ) => ( ( parseFloat( a.order_total_price ) + parseFloat( a.order_refunded_price ) ) - ( parseFloat( b.order_refunded_price ) + parseFloat( b.order_refunded_price ) ) ) * index );
                        break;
                    default:
                        break;
                };
            } )

            return {
                data: results.slice( from, to ),
                total: results.length,
                periods
            };
        } )
        .catch( error => console.log( error ) );
}

export const getOrder = ( id ) => {
    return axiosIntance.post( API_URL1 + '/get-order',{orderId:id} )
        .then( response => {
            return response.data.body;
        } )
}

export const orderConfirmPayment = ( id ) => {
    return axiosIntance.post( API_URL1 + '/order-confirm-payment',{orderId:id} )
        .then( response => {
            return response;
        } )
}

// 
export const getWithdraws = ( from = 0, to, filters ) => {
    return axios.get( )
        .then( response => {
            let results = response.data;

            filters && filters.forEach( filter => {
                results = results.filter( withdraw => {
                    return withdraw[ filter.id ] === filter.value;
                } );
            } );

            return {
                data: results.slice( from, to ),
                total: results.length
            };
        } )
        .catch( error => console.log( error ) );
}

// Apply coupons
export const applyCoupons = ( items, codes, newCode ) => {
    return axios.get(  )
        .then( response => {
            if ( !newCode || response.data.find( coupon => coupon.code === newCode ) ) {
                newCode && codes.push( newCode );
                let coupons = response.data.filter( coupon => codes.includes( coupon.code ) );
                let totalCounts = items.reduce( ( acc, cur ) => { return acc + cur.qty }, 0 );
                items = items.map( item => {
                    item.discount = 0;
                    return item;
                } );
                coupons = coupons.map( coupon => {
                    let discount = 0;
                    let tax = 0;
                    for ( let i = 0; i < items.length; i++ ) {
                        let discountAmount = 0;
                        let taxAmount = 0;
                        let taxRate = items[ i ].tax_rate ? items[ i ].tax_rate : 0;
                        if ( coupon.discount_type === 'percent' ) {
                            discountAmount = items[ i ].cost * items[ i ].qty * coupon.amount / 100;
                            taxAmount = discountAmount * taxRate / ( items[ i ].cost * items[ i ].qty );
                        } else if ( coupon.discount_type === 'product' ) {
                            discountAmount = items[ i ].qty * coupon.amount;
                            taxAmount = discountAmount * items[ i ].tax_rate / ( items[ i ].cost * items[ i ].qty );
                        } else {
                            discountAmount = coupons.amount * items[ i ].qty / totalCounts;
                            taxAmount = discountAmount * taxRate / ( items[ i ].cost * items[ i ].qty );
                        }
                        items[ i ].discount = discountAmount;
                        discount += discountAmount;
                        tax += taxAmount;
                    }
                    return {
                        ...coupon,
                        discount: discount,
                        tax: tax
                    };
                } );
                return {
                    items,
                    coupons
                };
            }
            return false;
        } )
        .catch( error => console.log( error ) );
}

export const getTaxStatusList = function ( from = 0, to ) {
    return axiosIntance.get( API_URL1 + '/get-tax-status-list' )
        .then( response => {
            let results = response.data.body;
            return {
                data: results.slice( from, to ),
                total: results.length
            }
        } )
        .catch( error => console.log( error ) );
}

export const addTaxStatus = function ( data ) {
    return axiosIntance.post( API_URL1 + '/add-tax-status',data )
        .then( response => {
            return response;
        } )
        .catch( error => console.log( error ) );
}

export const updateTaxStatus = function ( data ) {
    return axiosIntance.post( API_URL1 + '/tax-status-update',data )
        .then( response => {
            return response;
        } )
        .catch( error => console.log( error ) );
}

export const getTaxStatus = function (data) {
    return axiosIntance.post( API_URL1 + '/get-tax-status',{taxId:data})
        .then( response => {
            return response.data.body;
        } )
        .catch( error => console.log( error ) );
}

export const deleteTaxStatus = function ( data ) {
    return axiosIntance.post( API_URL1 + '/tax-status-delete',{taxId:data} )
        .then( response => {
            
            return response;

        } )
        .catch( error => console.log( error ) );
}

// ================ Tax ====================================

export const addTax = function ( data ) {
    return axiosIntance.post( API_URL1 + '/add-tax',data )
        .then( response => {
            return response;
        } )
        .catch( error => console.log( error ) );
}

export const deleteTax = function ( data ) {
    return axiosIntance.post( API_URL1 + '/tax-delete',{taxId:data} )
        .then( response => {
            
            return response;

        } )
        .catch( error => console.log( error ) );
}

export const getTaxList = function ( from = 0, to ) {
    return axiosIntance.get( API_URL1 + '/get-tax-list' )
        .then( response => {
            let results = response.data.body;
            return {
                data: results.slice( from, to ),
                total: results.length
            }
        } )
        .catch( error => console.log( error ) );
}

export const getTax = function (data) {
    return axiosIntance.post( API_URL1 + '/get-tax',{taxId:data})
        .then( response => {
            return response.data.body;
        } )
        .catch( error => console.log( error ) );
}

export const updateTax = function ( data ) {
    return axiosIntance.post( API_URL1 + '/tax-update',data )
        .then( response => {
            return response;
        } )
        .catch( error => console.log( error ) );
}

//============== Tax class ==================================

export const getTaxClassList = function ( from = 0, to ) {
    return axiosIntance.get( API_URL1 + '/get-tax-class-list' )
        .then( response => {
            let results = response.data.body;
            return {
                data: results.slice( from, to ),
                total: results.length
            }
        } )
        .catch( error => console.log( error ) );
}

export const addTaxClass = function ( data ) {
    return axiosIntance.post( API_URL1 + '/add-tax-class',data )
        .then( response => {
            return response;
        } )
        .catch( error => console.log( error ) );
}

export const updateTaxClass = function ( data ) {
    return axiosIntance.post( API_URL1 + '/tax-class-update',data )
        .then( response => {
            return response;
        } )
        .catch( error => console.log( error ) );
}

export const getTaxClass = function (data) {
    return axiosIntance.post( API_URL1 + '/get-tax-class',{taxId:data})
        .then( response => {
            
            return response.data.body;
        } )
        .catch( error => console.log( error ) );
}

export const deleteTaxClass = function ( data ) {
    return axiosIntance.post( API_URL1 + '/tax-class-delete',{taxId:data} )
        .then( response => {
            
            return response;

        } )
        .catch( error => console.log( error ) );
}

//=========== Stock status ===================================

export const getStockStatusList = function ( from = 0, to ) {
    return axiosIntance.get( API_URL1 + '/get-stock-status-list' )
        .then( response => {
            let results = response.data.body;
            return {
                data: results.slice( from, to ),
                total: results.length
            }
        } )
        .catch( error => console.log( error ) );
}

export const addStockStatus = function ( data ) {
    return axiosIntance.post( API_URL1 + '/add-stock-status',data )
        .then( response => {
            return response;
        } )
        .catch( error => console.log( error ) );
}

export const updateStockStatus = function ( data ) {
    return axiosIntance.post( API_URL1 + '/stock-status-update',data )
        .then( response => {
            return response;
        } )
        .catch( error => console.log( error ) );
}

export const getStockStatus = function (data) {
    return axiosIntance.post( API_URL1 + '/get-stock-status',{taxId:data})
        .then( response => {
            return response.data.body;
        } )
        .catch( error => console.log( error ) );
}

export const deleteStockStatus = function ( data ) {
    return axiosIntance.post( API_URL1 + '/stock-status-delete',{taxId:data})
        .then( response => {
            
            return response;

        } )
        .catch( error => console.log( error ) );
}

export const getSubCategory = function ( data ) {
    return axiosIntance.post( API_URL1 + '/get-sub-category',{catId:data} )
        .then( response => {
            if(response.data.body.session== -101){
                window.location.href = '/'
            }else{
                return response;
            }
        } )
        .catch( error => console.log( error ) );
}

//============== Brand =============================================

export const getBrandList = function ( from = 0, to ) {
    return axiosIntance.get( API_URL1 + '/get-brand-list')
        .then( response => {
            let results = response.data.body;
            return {
                data: results.slice( from, to ),
                total: results.length
            }
        } )
        .catch( error => console.log( error ) );
}

export const addBrand= function ( data ) {
    return axiosIntance.post( API_URL1 + '/add-brand',data )
        .then( response => {
            return response;
        } )
        .catch( error => console.log( error ) );
}

export const updateBrand = function ( data ) {
    return axiosIntance.post( API_URL1 + '/brand-update',data )
        .then( response => {
            return response;
        } )
        .catch( error => console.log( error ) );
}

export const getBrand = function (data) {
    return axiosIntance.post( API_URL1 + '/get-brand',{brandId:data})
        .then( response => {
            return response.data.body;
        } )
        .catch( error => console.log( error ) );
}

export const deleteBrand = function ( data ) {
    return axiosIntance.post( API_URL1 + '/brand-delete',{brandId:data} )
        .then( response => {
            return response;

        } )
        .catch( error => console.log( error ) );
}

export const syncBrands = function () {
    return axiosIntance.get( API_URL1 + '/sync-brand' )
        .then( response => {
            console.log(response)
            return response;
        } )
        .catch( error => console.log( error ) );
}

// ==================Thuiskopie =================================

export const addThuiskopie= function ( data ) {
    return axiosIntance.post( API_URL1 + '/add-thuiskopie',data )
        .then( response => {
            return response;
        } )
        .catch( error => console.log( error ) );
}

export const getThuiskopieList = function ( from = 0, to ) {
    return axiosIntance.get( API_URL1 + '/get-thuiskopie-list')
        .then( response => {
            
            let results = response.data.body;
            return {
                data: results.slice( from, to ),
                total: results.length
            }
        } )
        .catch( error => console.log( error ) );
}

export const getThuiskopie = function (data) {
    return axiosIntance.post( API_URL1 + '/get-thuiskopie',{thkId:data})
        .then( response => {
            return response.data.body;
        } )
        .catch( error => console.log( error ) );
}

export const updateThuiskopie = function ( data ) {
    return axiosIntance.post( API_URL1 + '/thk-update',data )
        .then( response => {
            return response;
        } )
        .catch( error => console.log( error ) );
}

export const deleteThuiskopie = function ( data ) {
    return axiosIntance.post( API_URL1 + '/thk-delete',{thkId:data} )
        .then( response => {
            return response;

        } )
        .catch( error => console.log( error ) );
}


// ================= Store ======================================

export const getStoreList = function ( from = 0, to ) {
    return axiosIntance.get( API_URL1 + '/get-store-list')
        .then( response => {
            let results = response.data.body;
            return {
                data: results.slice( from, to ),
                total: results.length
            }
        } )
        .catch( error => console.log( error ) );
}

export const syncStore = function () {
    return axiosIntance.get( API_URL1 + '/sync-store' )
        .then( response => {
            console.log(response)
            return response;
        } )
        .catch( error => console.log( error ) );
}

export const addStore = function ( data ) {
    return axiosIntance.post( API_URL1 + '/add-store',data)
        .then( response => {
            return response;
        } )
        .catch( error => console.log( error ) );
}

export const deleteStore = function ( data ) {
    return axiosIntance.post( API_URL1 + '/store-delete',{sId:data} )
        .then( response => {
            return response;

        } )
        .catch( error => console.log( error ) );
}

export const getStore = function (data) {
    return axiosIntance.post( API_URL1 + '/get-store',{sId:data})
        .then( response => {
            return response.data.body;
        } )
        .catch( error => console.log( error ) );
}

export const updateStore = function ( data ) {
    return axiosIntance.post( API_URL1 + '/store-update',data )
        .then( response => {
            return response;
        } )
        .catch( error => console.log( error ) );
}

// ============ product apis =========================

export const getProducts = function ( from = 0, to, filters, sortBy, withVariants = false ) {
    return axiosIntance.post( API_URL1 + '/get-products', { searchData:filters!=''? filters[0].value : '' } )
        .then( response => {
                let results = response.data.body;
                if ( withVariants ) {
                    results = results.reduce( ( acc, cur ) => {
                        acc.push( cur );
                        if ( cur.variants ) {
                            acc = [
                                ...acc,
                                ...cur.variants
                            ];
                        }
                        return acc;
                    }, [] );
                }
                sortBy && sortBy.forEach( sort => {
                    let index = sort.desc ? -1 : 1;
                    switch ( sort.id ) {
                        case 'name':
                            results = results.sort( ( a, b ) => a.name < b.name ? -index : index );
                        case 'brand':
                            results = results.sort( ( a, b ) => a.brand < b.brand ? -index : index );
                            break;
                        case 'categories':
                            results = results.sort( ( a, b ) => a.categories < b.categories ? -index : index );
                            break;
                        case 'createdAt':
                            results = results.sort( ( a, b ) => ( new Date( a.createdAt ) - new Date( b.createdAt ) ) * index );
                            break;
                        default:
                            break;
                    }
                } );

                return {
                    data: results.slice( from, to ),
                    total: results.length
                }
        } )
        .catch( error => console.log( error ) );
}

export const getProduct = function ( id ) {
    return axiosIntance.post( API_URL1 + '/get-product',{proId:id})
        .then( response => {
            if(response.data.body.session== -101){
                window.location.href = '/'
            }else{
                return response.data.body;
            }
        } )
        .catch( error => console.log( error ) );
}

export const getProductDetails = function ( id ) {
    return axiosIntance.post( API_URL1 + '/get-product-details',{proId:id})
        .then( response => {
            if(response.data.body.session== -101){
                window.location.href = '/'
            }else{
                return response.data.body;
            }
        } )
        .catch( error => console.log( error ) );
}

export const addProduct = function ( data ) {
    return axiosIntance.post( API_URL1 + '/add-product',data )
        .then( response => {
            if(response.data.body.session== -101){
                window.location.href = '/'
            }else{
                return response;
            }
        } )
        .catch( error => console.log( error ) );
}

export const getStockQTY = function ( data ) {
    return axiosIntance.post( API_URL1 + '/get-stockqty-fromsku',{proId:data} )
        .then( response => {
            if(response.data.body.session== -101){
                window.location.href = '/'
            }else{
                return response;
            }
        } )
        .catch( error => console.log( error ) );
}

export const updateProduct = function ( data ) {
    return axiosIntance.post( API_URL1 + '/product-update',data)
        .then( response => {
            if(response.data.body.session== -101){
                window.location.href = '/'
            }else{
                return response;
            }
        } )
        .catch( error => console.log( error ) );
}

export const deleteProduct = function ( data ) {
    return axiosIntance.post( API_URL1 + '/product-delete',{proId:data} )
        .then( response => {
            return response;
        } )
        .catch( error => console.log( error ) );
}

// =================== coupon ================================

export const addCoupon = function ( data ) {
    return axiosIntance.post( API_URL1 + '/add-coupon',data )
        .then( response => {
            
            return response;
        } )
        .catch( error => console.log( error ) );
}

export const getCoupons = function ( from = 0, to, filters, sortBy ) {
    return axiosIntance.get( API_URL1 + '/get-coupons' )
        .then( response => {
            let results = response.data.body;
            
            filters && filters.forEach( filter => {
                results = results.filter( coupon => {
                    if ( filter.id == 'discount_type' ){
                        return coupon && coupon.discountType == filter.value ;
                    }else{
                        return coupon[ filter.id ].search( new RegExp( filter.value, 'i' ) ) >= 0;
                    }
                } )
            } );

            sortBy && sortBy.forEach( sort => {
                let index = sort.desc ? -1 : 1;
                switch ( sort.id ) {
                    case 'created_at':
                        results = results.sort( ( a, b ) => ( new Date( a.created_at ) - new Date( b.created_at ) ) * index );
                        break;
                    default:
                        break;
                }
            } );

            console.log('filter result',results);
            return {
                data: results.slice( from, to ),
                total: results.length
            }
        } )
        .catch( error => console.log( error ) );
}

export const getCoupon = function ( id ) {
    return axiosIntance.post( API_URL1 + '/get-coupon',{couponId:id} )
        .then( response => {
            return response.data.body[0];
        } );
}

//================= Auth =========================

export const login = function ( data ) {
    return axios.post( API_URL1 + '/admin-login',data)
        .then( response => {
            return response;
        } )
        .catch( error => console.log( error ) );
}

export const logout = function ( data ) {
    return axiosIntance.post( API_URL1 + '/admin-logout',data)
        .then( response => {
            if(response.data.body.session== -101){
                window.location.href = '/'
            }else{
                return response;
            }
        } )
        .catch( error => console.log( error ) );
}

export const addUserLevel = function ( data ) {
    return axiosIntance.post( API_URL1 + '/add-user-level',data)
        .then( response => {
            return response;
        } )
        .catch( error => console.log( error ) );
}

export const getUserLevels= function ( from = 0, to ) {
    return axiosIntance.get( API_URL1 + '/get-user-levels')
        .then( response => {
            let results = response.data.body;
            return {
                data: results.slice( from, to ),
                total: results.length
            }
        } )
        .catch( error => console.log( error ) );
}

export const deleteLevel = function ( data ) {
    return axiosIntance.post( API_URL1 + '/user-level-delete',{levelId:data} )
        .then( response => {
            return response;

        } )
        .catch( error => console.log( error ) );
}

export const getUserLevel = function (data) {
    return axiosIntance.post( API_URL1 + '/get-user-level',{levelId:data})
        .then( response => {
            return response.data.body;
        } )
        .catch( error => console.log( error ) );
}

export const updateUserLevel = function ( data ) {
    return axiosIntance.post( API_URL1 + '/user-level-update',data)
        .then( response => {
            return response;
        } )
        .catch( error => console.log( error ) );
}

//===================== country =====================================

export const addCountry = function ( data ) {
    return axiosIntance.post( API_URL1 + '/add-country',data )
        .then( response => {
            return response;
        } )
        .catch( error => console.log( error ) );
}

export const getCountryList = function ( from = 0, to ) {
    return axiosIntance.get( API_URL1 + '/get-admin-country-list')
        .then( response => {
            let results = response.data.body;
            return {
                data: results.slice( from, to ),
                total: results.length
            }
        } )
        .catch( error => console.log( error ) );
}

export const deleteCountry = function ( data ) {
    return axiosIntance.post( API_URL1 + '/country-delete',{cId:data} )
        .then( response => {
            return response;

        } )
        .catch( error => console.log( error ) );
}

export const getCountry = function (data) {
    return axiosIntance.post( API_URL1 + '/get-country',{cId:data})
        .then( response => {
            return response.data.body;
        } )
        .catch( error => console.log( error ) );
}

export const updateCountry = function ( data ) {
    return axiosIntance.post( API_URL1 + '/country-update',data )
        .then( response => {
            return response;
        } )
        .catch( error => console.log( error ) );
}

//============== Dashboard =====================================

export const getDashboardData = function (  ) {
    return axiosIntance.get( API_URL1 + '/dashboard' )
        .then( response => {
            if(response.data.body.session== -101){
                window.location.href = '/'
            }else{
                return response;
            }
        } )
        .catch( error => console.log( error ) );
}

//============== End =============================================

export const confirmRefund = function ( id ) {
    return axiosIntance.post( API_URL1 + '/refund-status-update',{itemId:id} )
        .then( response => {
            return response;
        } )
        .catch( error => console.log( error ) );
}

export const orderStatusUpdate = function ( data ) {
    return axiosIntance.post( API_URL1 + '/order-status-update',data )
        .then( response => {
            return response;
        } )
        .catch( error => console.log( error ) );
}

export const updateAdminProfile = function ( data ) {
    return axiosIntance.post( API_URL1 + '/update-admin-profile',data )
        .then( response => {
            return response;
        } )
        .catch( error => console.log( error ) );
}

//===================== Banner ===============================

export const addBanner = function ( data ) {
    return axiosIntance.post( API_URL1 + '/add-banner',data )
        .then( response => {
            return response;
        } )
        .catch( error => console.log( error ) );
}

export const getBannerList = function ( from = 0, to ) {
    return axiosIntance.get( API_URL1 + '/get-banners')
        .then( response => {
            let results = response.data.body;
            return {
                data: results,
                total: results.length
            }
        } )
        .catch( error => console.log( error ) );
}

export const deleteBanner = function ( data ) {
    return axiosIntance.post( API_URL1 + '/banner-delete',{cId:data} )
        .then( response => {
            return response;

        } )
        .catch( error => console.log( error ) );
}

export const getBanner = function (data) {
    return axiosIntance.post( API_URL1 + '/get-banner',{cId:data})
        .then( response => {
            return response.data.body;
        } )
        .catch( error => console.log( error ) );
}

export const updateBanner = function ( data ) {
    return axiosIntance.post( API_URL1 + '/banner-update',data )
        .then( response => {
            console.log(response)
            return response;
        } )
        .catch( error => console.log( error ) );
}

// ================ Sync category =============================

export const syncParentCategory = function () {
    return axiosIntance.get( API_URL1 + '/sync-parent-category' )
        .then( response => {
            console.log(response)
            return response;
        } )
        .catch( error => console.log( error ) );
}

export const syncChildCategory = function () {
    return axiosIntance.get( API_URL1 + '/sync-child-category' )
        .then( response => {
            return response;
        } )
        .catch( error => console.log( error ) );
}

export const syncProducts = function () {
    return axiosIntance.get( API_URL1 + '/sync-products' )
        .then( response => {
            return response;
        } )
        .catch( error => console.log( error ) );
}

export const syncStocks = function () {
    return axiosIntance.get( API_URL1 + '/sync-stocks' )
        .then( response => {
            return response;
        } )
        .catch( error => console.log( error ) );
}

export const uploadProductsFile = function ( data ) {
    return axiosIntance.post( API_URL1 + '/upload-products',data )
        .then( response => {
            return response;
        } )
        .catch( error => console.log( error ) );
}